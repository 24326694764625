import React from "react";

import { Props } from "./types";
import { Container, BackgroundImage, Border } from "./styles";

const BackgroundSection = ({
  children,
  className = "",
  fluid,
  ...props
}: Props): React.ReactElement => (
  <Container {...props}>
    <Border />
    <BackgroundImage Tag="section" className={className} fluid={fluid}>
      {children}
    </BackgroundImage>
  </Container>
);

export default BackgroundSection;
