import tw, { styled } from "twin.macro";

import { Props } from "./types";

const Container = styled.div(({ size }: Props) => [
  tw`mx-auto py-16 lg:py-20 px-8 lg:px-12`,
  size === "lg" && tw`max-w-7xl`,
  size === "xl" && tw`max-w-9xl`,
]);

export { Container };
