import BaseBackgroundImage from "gatsby-background-image";
import tw, { styled } from "twin.macro";

const Container = styled.div(() => [tw`relative`]);

const BackgroundImage = styled(BaseBackgroundImage)(() => [
  tw`w-full pt-24 pb-40 md:pt-60 md:pb-60 xl:pt-80 xl:pb-80 bg-cover
    overflow-hidden bg-gray-900`,
]);

const Border = styled.div(() => [tw`absolute h-10 w-screen bottom-0 bg-gray-100 opacity-30 z-10`]);

export { Container, BackgroundImage, Border };
