import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import List from "components/Common/List";
import { uuid } from "utils/ui";

import { Props } from "./types";
import { Item, IconContainer, Children } from "./styles";

const IconList = ({ items, mt = false }: Props): React.ReactElement => (
  <List
    type="none"
    items={items.map(({ Icon, children }, index) => (
      <Item mt={mt} index={index} key={uuid()}>
        <IconContainer>
          <FontAwesomeIcon icon={Icon} size="lg" />
        </IconContainer>
        <Children>{children}</Children>
      </Item>
    ))}
  />
);

export default IconList;
