import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Gallery = (): React.ReactElement => (
  <section data-testid="introduction" className="bg-gray-900 text-white">
    <div className="grid gap-4 py-4 lg:px-4 grid-cols-1 xl:grid-cols-2">
      <span>
        <StaticImage
          className="h-full"
          src="../../../assets/images/front-unedited.jpg"
          alt="Front of the home"
        />
      </span>
      <span>
        <StaticImage className="h-full" src="../../../assets/images/kitchen-1.jpg" alt="Kitchen" />
      </span>
      <span>
        <StaticImage className="h-full" src="../../../assets/images/kitchen-2.jpg" alt="Kitchen" />
      </span>
      <span>
        <StaticImage
          className="h-full"
          src="../../../assets/images/bedroom-2.jpg"
          alt="Bedroom 1"
        />
      </span>
      <span>
        <StaticImage
          className="h-full"
          src="../../../assets/images/bedroom-3.jpg"
          alt="Bedroom 2"
        />
      </span>
      <span>
        <StaticImage className="h-full" src="../../../assets/images/verandah.jpg" alt="Verandah" />
      </span>
      <span>
        <StaticImage
          className="h-full"
          src="../../../assets/images/outdoors-1.jpg"
          alt="Backyard"
        />
      </span>
      <span>
        <StaticImage
          className="h-full"
          src="../../../assets/images/outdoors-2.jpg"
          alt="Backyard"
        />
      </span>
      <span>
        <StaticImage
          className="h-full"
          src="../../../assets/images/outdoors-3.jpg"
          alt="Backyard"
        />
      </span>
      <span>
        <StaticImage
          className="h-full"
          src="../../../assets/images/outdoors-4.jpg"
          alt="Backyard"
        />
      </span>
      <span>
        <StaticImage
          className="h-full"
          src="../../../assets/images/ocean-view.jpg"
          alt="Ocean view"
        />
      </span>
      <span>
        <StaticImage
          className="h-full"
          src="../../../assets/images/verandah-windows.jpg"
          alt="Window view of verandah"
        />
      </span>
      <span className="xl:col-span-2">
        <StaticImage className="h-full" src="../../../assets/images/sunset.jpg" alt="Sunset" />
      </span>
    </div>
  </section>
);

export default Gallery;
