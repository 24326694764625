import React from "react";

import { Props } from "./types";
import { Title as StyledTitle } from "./styles";

const Title = ({
  children,
  size,
  Tag,
  className = "",
  weight = "bold",
  border = false,
  ...props
}: Props): React.ReactElement => {
  const customMargin = className?.indexOf("mb-") !== -1;

  return (
    <StyledTitle
      className={className}
      weight={weight}
      border={border}
      size={size}
      customMargin={customMargin}
      {...props}
    >
      {children}
    </StyledTitle>
  );
};

export default Title;
