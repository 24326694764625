module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aldinga Beach Bliss","short_name":"Beach Bliss","start_url":"/","background_color":"#DE911D","theme_color":"#DE911D","display":"minimal-ui","icons":[],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://017564c66ceb403f859f9c123d512c7a@o235025.ingest.sentry.io/5654302"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
