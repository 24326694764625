import tw, { styled } from "twin.macro";

import { ContainerProps } from "./types";

const Container = styled.div(({ type }: ContainerProps) => [
  type === "disc" && tw`list-disc`,
  type === "decimal" && tw`list-decimal`,
  type === "none" && tw`list-none`,
]);

const Item = styled.li();

export { Container, Item };
