import tw, { styled } from "twin.macro";

import { ContainerProps, InnerProps } from "./types";

const transition = tw`transition delay-75`;

const Container = styled.div(({ colour }: ContainerProps) => [
  transition,
  tw`inline-flex rounded-md`,
  colour === "primary" && tw`shadow-xl hover:shadow-2xl`,
  colour === "secondary" && tw`shadow-md hover:shadow-lg`,
]);

const Inner = styled.a(({ colour }: InnerProps) => [
  transition,
  tw`w-full inline-flex items-center justify-center px-6 py-4 border text-lg font-bold rounded-md`,
  colour === "primary" && tw`text-white bg-blue-600 group-hover:bg-blue-700`,
  colour === "secondary" && tw`text-blue-600 bg-white group-hover:bg-gray-50`,
]);

export { Container, Inner };
