import React from "react";
import { useTrail, animated } from "react-spring";

import { uuid } from "utils/ui";

export type Props = {
  children: React.ReactNode;
  [x: string]: unknown;
};

const Trail = ({ children, ...props }: Props): React.ReactElement => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 1000, friction: 150 },
    opacity: 1,
    x: 0,
    from: { opacity: 0, x: -25 },
  });

  return (
    <div className="trails-main" {...props}>
      <div>
        {trail.map(({ x, ...rest }, index) => (
          <animated.div
            key={uuid()}
            className="trails-text"
            style={{ ...rest, transform: x.interpolate((y) => `translate3d(0,${y}px,0)`) }}
          >
            <animated.div>{items[index]}</animated.div>
          </animated.div>
        ))}
      </div>
    </div>
  );
};

export default Trail;
