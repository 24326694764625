import React from "react";

import Layout from "components/Layout/Layout";
import SEO from "components/Misc/SEO";

import Amenities from "../Amenities";
import Introduction from "../Introduction";
import Gallery from "../Gallery";
import Hero from "../Hero";
import Header from "../Header";

const Main = (): React.ReactElement => (
  <Layout>
    <SEO title="Stunning luxury beach house" />
    <Hero />
    <Header />
    <Introduction />
    <Amenities />
    <Gallery />
  </Layout>
);

export default Main;
