// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-page-amenities-amenities-tsx": () => import("./../../../src/pages/IndexPage/Amenities/Amenities.tsx" /* webpackChunkName: "component---src-pages-index-page-amenities-amenities-tsx" */),
  "component---src-pages-index-page-amenities-index-ts": () => import("./../../../src/pages/IndexPage/Amenities/index.ts" /* webpackChunkName: "component---src-pages-index-page-amenities-index-ts" */),
  "component---src-pages-index-page-gallery-gallery-tsx": () => import("./../../../src/pages/IndexPage/Gallery/Gallery.tsx" /* webpackChunkName: "component---src-pages-index-page-gallery-gallery-tsx" */),
  "component---src-pages-index-page-gallery-index-ts": () => import("./../../../src/pages/IndexPage/Gallery/index.ts" /* webpackChunkName: "component---src-pages-index-page-gallery-index-ts" */),
  "component---src-pages-index-page-header-header-tsx": () => import("./../../../src/pages/IndexPage/Header/Header.tsx" /* webpackChunkName: "component---src-pages-index-page-header-header-tsx" */),
  "component---src-pages-index-page-header-index-ts": () => import("./../../../src/pages/IndexPage/Header/index.ts" /* webpackChunkName: "component---src-pages-index-page-header-index-ts" */),
  "component---src-pages-index-page-hero-hero-tsx": () => import("./../../../src/pages/IndexPage/Hero/Hero.tsx" /* webpackChunkName: "component---src-pages-index-page-hero-hero-tsx" */),
  "component---src-pages-index-page-hero-index-ts": () => import("./../../../src/pages/IndexPage/Hero/index.ts" /* webpackChunkName: "component---src-pages-index-page-hero-index-ts" */),
  "component---src-pages-index-page-index-ts": () => import("./../../../src/pages/IndexPage/index.ts" /* webpackChunkName: "component---src-pages-index-page-index-ts" */),
  "component---src-pages-index-page-introduction-index-ts": () => import("./../../../src/pages/IndexPage/Introduction/index.ts" /* webpackChunkName: "component---src-pages-index-page-introduction-index-ts" */),
  "component---src-pages-index-page-introduction-introduction-tsx": () => import("./../../../src/pages/IndexPage/Introduction/Introduction.tsx" /* webpackChunkName: "component---src-pages-index-page-introduction-introduction-tsx" */),
  "component---src-pages-index-page-main-index-ts": () => import("./../../../src/pages/IndexPage/Main/index.ts" /* webpackChunkName: "component---src-pages-index-page-main-index-ts" */),
  "component---src-pages-index-page-main-main-tsx": () => import("./../../../src/pages/IndexPage/Main/Main.tsx" /* webpackChunkName: "component---src-pages-index-page-main-main-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

