import tw, { styled } from "twin.macro";

import Trail from "components/Misc/Animation/Trail";
import BaseButton from "components/Common/Button";

const Nav = styled.nav(() => [tw`bg-gray-50 border-b-4 border-blue-700`]);

const Inner = styled.div(() => [tw`lg:flex lg:items-center lg:justify-between lg:text-left`]);

const TitleTrail = styled(Trail)(() => [tw`font-semibold lg:font-bold`]);

const TitleTop = styled.span(() => [tw`block`]);

const TitleBottom = styled.span(() => [tw`block text-blue-600`]);

const ButtonContainer = styled.div(() => [
  tw`mt-10 flex flex-col md:flex-row lg:mt-0 lg:flex-shrink-0`,
]);

const ButtonTrail = styled(Trail)(() => [tw`mb-3 md:mb-0 md:mr-4`]);

const Button = styled(BaseButton)(() => [tw`w-full`]);

export { Nav, Inner, TitleTrail, TitleTop, TitleBottom, ButtonContainer, ButtonTrail, Button };
