import tw, { styled } from "twin.macro";

const Container = styled.div(() => [tw`font-serif`]);

const Main = styled.div(() => [tw`text-xl leading-10 font-light`]);

const Footer = styled.footer(() => [
  tw`bg-gray-900 text-white font-normal text-lg text-center pb-6 pt-2`,
]);

const FooterIconsCredit = styled.div(() => [tw`text-xs mt-3 font-light`]);

export { Container, Main, Footer, FooterIconsCredit };
