import React from "react";

import Container from "components/Common/Container";
import Trail from "components/Misc/Animation/Trail";

const Introduction = (): React.ReactElement => (
  <section data-testid="introduction">
    <Container className="text-gray-800">
      <Trail>
        <p>
          This new stunning, luxurious, and completely self-contained holiday home is situated on
          the <strong>Esplanade at Aldinga Beach</strong>.
        </p>
        <p>
          Perched above the beautiful Aldinga Beach with{" "}
          <strong>stunning views of the coast and Sellicks cliffs</strong>, this home is situated at
          a prime location. It&rsquo;s a two minute stroll to three popular cafes, and just a short
          drive to the McLaren Vale wine district, with many cellar doors and restaurants to choose
          from.
        </p>
        <p>
          This <strong>modern holiday home</strong> has just been completed for your convenience.
          Soak in the <strong>best sea and sunset views</strong> from the comfort of the couch in
          the living room, or step onto the balcony and feel the ocean breeze.
        </p>
        <p>
          We can accommodate up to <strong>8 people</strong> in <strong>3 bedrooms</strong>. Bed
          linen and bathroom towels are supplied. The beds are made for your arrival so you can
          unpack and relax. No need to bring any linen—just beach towels.
        </p>
        <p>
          <strong>We love this location</strong> and want to give <i>you,</i> our guests, the
          opportunity to experience the magic of living this close to the ocean and enjoying the
          many local attractions the district has to offer.
        </p>
      </Trail>
    </Container>
  </section>
);

export default Introduction;
