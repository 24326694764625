import React from "react";

import { uuid } from "utils/ui";
import Trail from "components/Misc/Animation/Trail";

import { Props } from "./types";
import { Container, Item } from "./styles";

const List = ({ items, className = "", type = "disc" }: Props): React.ReactElement => (
  <Container className={className} type={type}>
    <Trail className="leading-loose">
      {items.map((item) => (
        <Item key={uuid()}>{item}</Item>
      ))}
    </Trail>
  </Container>
);

export default List;
