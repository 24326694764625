import React from "react";

import Trail from "components/Misc/Animation/Trail";
import Link from "components/Common/Link";

import { Props } from "./types";
import { Container, Main, Footer, FooterIconsCredit } from "./styles";

const Layout = ({ children }: Props): React.ReactElement => (
  <Container>
    <Main>{children}</Main>
    <Footer>
      <Trail>
        <div>Aldinga Beach Bliss &copy; {new Date().getFullYear()}</div>
        <FooterIconsCredit>
          Icons by{" "}
          <Link target="_blank" rel="noreferrer" href="https://fontawesome.com">
            Font Awesome
          </Link>
        </FooterIconsCredit>
      </Trail>
    </Footer>
  </Container>
);
export default Layout;
