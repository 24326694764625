import tw, { styled } from "twin.macro";

import { TitleProps } from "./types";

const Title = styled.div(({ weight, size, border, customMargin }: TitleProps) => [
  tw`font-title tracking-tight`,
  weight === "bold" && tw`font-bold`,
  weight === "semibold" && tw`font-semibold`,
  weight === "normal" && tw`font-normal`,
  size === "sm" && tw`text-4xl`,
  size === "sm" && !customMargin && tw`mb-2`,
  size === "md" && tw`text-4xl md:text-5xl`,
  size === "md" && !customMargin && tw`mb-3`,
  size === "lg" && tw`text-5xl md:text-6xl`,
  size === "lg" && !customMargin && tw`mb-4`,
  size === "xl" && tw`text-7xl md:text-8xl`,
  size === "xl" && !customMargin && tw`mb-5`,
  border && tw`border-b pb-4`,
]);

export { Title };
