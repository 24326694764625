import React from "react";

import { Props } from "./types";
import { Link as StyledLink } from "./styles";

const Link = ({ children, ...props }: Props): React.ReactElement => (
  <StyledLink {...props}>{children}</StyledLink>
);

export default Link;
