import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import { Helmet, HelmetProps } from "react-helmet";

import ogImage from "assets/images/og-image.jpg";

export type Props = {
  description?: string;
  title?: string;
  meta?: HelmetProps["meta"];
};

const SEO = ({ description, meta = [], title }: Props): React.ReactElement => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      bodyAttributes={{ class: "overflow-x-hidden m-0" }}
      title={title}
      titleTemplate={title ? `${defaultTitle} | %s` : defaultTitle}
      meta={[
        {
          name: "description",
          content: metaDescription,
        },
        {
          name: "og:title",
          content: title,
        },
        {
          name: "og:description",
          content: metaDescription,
        },
        {
          name: "og:image",
          content: `${site.siteMetadata.siteUrl}${ogImage}`,
        },
        {
          name: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:title",
          content: title,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
        ...meta,
      ]}
    >
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600;700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;1,300&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};

export default SEO;
