import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { Props } from "./types";
import { Container, Inner } from "./styles";

const Button = ({
  children,
  className = "",
  colour = "primary",
  href,
  icon,
}: Props): React.ReactElement => (
  <Container colour={colour} className={`${className} group`}>
    <Inner href={href} colour={colour}>
      {icon && <FontAwesomeIcon data-testid="icon" icon={icon} size="lg" className="mr-3" />}
      {children}
    </Inner>
  </Container>
);

export default Button;
