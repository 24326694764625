import tw, { styled } from "twin.macro";

import { ItemProps } from "./types";

const Item = styled.div(({ index, mt }: ItemProps) => [
  tw`flex flex-col md:flex-row justify-start`,
  (index !== 0 || mt) && tw`mt-8 md:mt-5`,
]);

const IconContainer = styled.span(() => [tw`block md:mr-3 md:w-10 flex-shrink-0 text-blue-900`]);

const Children = styled.span(() => [tw`mt-2 md:mt-0 text-lg`]);

export { Item, IconContainer, Children };
