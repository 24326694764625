import {
  faBath,
  faBed,
  faChair,
  faChild,
  faGlassCheers,
  faHome,
  faSink,
  faSun,
  faToilet,
  faTv,
  faUtensils,
  faWifi,
  faWind,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";

import Container from "components/Common/Container";
import IconList from "components/Common/IconList";
import Title from "components/Common/Title";
import Trail from "components/Misc/Animation/Trail";

import { AmenitiesContainer, Grid } from "./styles";

const Amenities = (): React.ReactElement => (
  <AmenitiesContainer data-testid="amenities">
    <Container>
      <Trail>
        <Title Tag="h3" size="lg" className="mb-8" border>
          Amenities
        </Title>
      </Trail>
      <Grid>
        <IconList
          items={[
            {
              Icon: faBed,
              children: (
                <>
                  <strong>3 bedrooms</strong>; two with queen beds and ceiling fans; and one with
                  two sets of bunks.
                </>
              ),
            },
            {
              Icon: faBath,
              children: (
                <>
                  <strong>Deluxe master bathroom</strong> with bathtub.
                </>
              ),
            },
            {
              Icon: faGlassCheers,
              children: (
                <>
                  <strong>Stunning, open living area</strong> with huge floor to ceiling glass
                  windows and sliding doors, leading to a large undercover balcony with fantastic
                  ocean front views.
                </>
              ),
            },
            {
              Icon: faTv,
              children: (
                <>
                  <strong>Large living area</strong> with 75-inch smart TV.
                </>
              ),
            },
            {
              Icon: faUtensils,
              children: (
                <>
                  <strong>Gourmet kitchen</strong> with stainless appliances, stone benchtops,
                  microwave, dishwasher, and filtered drinking water.
                </>
              ),
            },
            {
              Icon: faWind,
              children: (
                <>
                  Reverse-cycle <strong>air conditioner</strong> for your comfort.
                </>
              ),
            },
          ]}
        />
        <IconList
          mt
          items={[
            {
              Icon: faChair,
              children: (
                <>
                  <strong>Open dining area.</strong>
                </>
              ),
            },
            {
              Icon: faSun,
              children: (
                <>
                  <strong>Undercover outdoor area</strong> with BBQ and seating.
                </>
              ),
            },
            {
              Icon: faChild,
              children: (
                <>
                  <strong>Living area for the kids</strong> with 55-inch smart TV.
                </>
              ),
            },
            {
              Icon: faSink,
              children: (
                <>
                  <strong>Separate laundry</strong> (ironing board, iron, and washing machine).
                </>
              ),
            },
            {
              Icon: faToilet,
              children: (
                <>
                  Separate <strong>powder room</strong> and toilet.
                </>
              ),
            },
            {
              Icon: faWifi,
              children: (
                <>
                  Unlimited <strong>NBN internet.</strong>
                </>
              ),
            },
            {
              Icon: faHome,
              children: (
                <>
                  Spacious <strong>2 story</strong> floor plan.
                </>
              ),
            },
          ]}
        />
      </Grid>
    </Container>
  </AmenitiesContainer>
);

export default Amenities;
