import { faPaperPlane, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import React from "react";

import Title from "components/Common/Title";
import { EMAIL, PHONE } from "config/constants";
import Container from "components/Common/Container";
import Trail from "components/Misc/Animation/Trail";

import {
  Nav,
  Inner,
  TitleTrail,
  TitleTop,
  TitleBottom,
  ButtonContainer,
  ButtonTrail,
  Button,
} from "./styles";

const Header = (): React.ReactElement => (
  <Nav data-testid="header">
    <Container>
      <Inner>
        <Title Tag="h2" size="sm">
          <TitleTrail>
            <TitleTop>Ready to relax?</TitleTop>
            <TitleBottom>Arrange a booking now.</TitleBottom>
          </TitleTrail>
        </Title>
        <ButtonContainer>
          <ButtonTrail>
            <Button href={`mailto:${EMAIL}`} colour="primary" icon={faPaperPlane}>
              {EMAIL}
            </Button>
          </ButtonTrail>
          <Trail>
            <Button href={`tel:${PHONE}`} className="w-full" colour="secondary" icon={faPhoneAlt}>
              {PHONE}
            </Button>
          </Trail>
        </ButtonContainer>
      </Inner>
    </Container>
  </Nav>
);

export default Header;
