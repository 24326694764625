import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import BackgroundSection from "components/Layout/BackgroundSection";
import Trail from "components/Misc/Animation/Trail";

import { Inner, MainTitle, Subtitle } from "./styles";

const Hero = (): React.ReactElement => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "front.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );
  const imageData = data.desktop.childImageSharp.fluid;

  return (
    <BackgroundSection data-testid="hero" fluid={imageData}>
      <Inner>
        <Trail>
          <MainTitle Tag="h1" size="xl" data-testid="title">
            Aldinga Beach Bliss
          </MainTitle>
          <Subtitle Tag="h2" size="md" weight="normal" data-testid="subtitle">
            A luxurious, brand new beach house
          </Subtitle>
        </Trail>
      </Inner>
    </BackgroundSection>
  );
};

export default Hero;
