import tw, { styled } from "twin.macro";

import Title from "components/Common/Title";

const Inner = styled.div(() => tw`max-w-7xl px-8 text-white lg:text-center mx-auto`);

const MainTitle = styled(Title)(() => tw`text-shadow-xl`);

const Subtitle = styled(Title)(() => tw`text-shadow-xl mb-0 text-gray-200 opacity-95`);

export { Inner, MainTitle, Subtitle };
