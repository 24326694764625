import React from "react";

import { Props } from "./types";
import { Container as StyledContainer } from "./styles";

const Container = ({ children, className = "", size = "lg" }: Props): React.ReactElement => (
  <StyledContainer size={size} className={className}>
    {children}
  </StyledContainer>
);

export default Container;
